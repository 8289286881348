.service-card {
  display: flex;
  border: 1px solid #bbc5d7;
  border-radius: em(4);
  box-shadow: 0 em(2) em(4) rgba(0, 0, 0, .2);
  background-color: white;
  padding: em(23) em(30) em(26);
  text-align: left;
  flex-direction: column;
  &__header {
    border-bottom: 1px solid #dce1e8;
  }
  &__body {
    padding-top: em(19);
    padding-bottom: em(50);
    flex-grow: 1;
  }
  &__footer {
    border-top: 1px solid #dce1e8;
    padding-top: em(19);
    a {
      transition: .25s color ease-in-out;
      text-decoration: none;
      line-height: (30 / 16);
      color: #0042ba;
      font-size: em(16);
      font-weight: 700;
      &:hover {
        color: $blue-light;
      }
    }
  }
  &__title {
    margin-top: em(20, 30);
    margin-bottom: em(19, 30);
  }
  &__icon {
    margin: 0 !important;
    &__image {
      width: auto;
      height: em(57);
    }
  }
}

.service-cards__flexible {
  background-color: #f7f7f7;
  padding-top: em(35);
  padding-bottom: em(35);
  &--two {
    > span > div {
      &, .primary-content-container &.hs_cos_wrapper_type_rich_text {
        @media (min-width: 768px) {
          flex-basis: 50%;
        }
      }
    }
  }
  &--three {
    > span > div {
      &, .primary-content-container &.hs_cos_wrapper_type_rich_text {
        @media (min-width: 768px) and (max-width: 991px) {
          flex-basis: 50%;
        }
        @media (min-width: 992px) {
          flex-basis: 33.33333%;
        }
      }
    }
  }
  > span {
    @extend %constrain;
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    @media (min-width: 768px) {
      align-items: flex-start;
      flex-wrap: wrap;
    }
    > div {
      &,
      .primary-content-container &.hs_cos_wrapper_type_rich_text {
        padding-right: 0 !important;
        padding-left: 0 !important;
        .service-card {
          @media (max-width:767px) {
            margin: em(15) 0;
          }
          @media (min-width:768px) {
            margin: em(15);
          }
        }
      }
      .primary-content-container &.hs_cos_wrapper_type_rich_text {
        margin: em(15) 0 !important;
      }
    }
  }
}

.hs-inline-edit .service-cards__flexible {
  > span {
    flex-direction: column !important;
    > div {
      margin-right: 0 !important;
      margin-left: 0 !important;
      width: 100% !important;
    }
  }
}
