$gray-lighter: #f2f2f2;
$gray-light: #b2b4b2;
$gray: #ccc;
$gray-dark: #666;
$gray-darker: #222;
$blue-medium: #003087;
$blue-dark: #002855;
$blue-light: #558cf2;
$blue-shade: #8e9fbc;
$gold: #cc8a00;
$primary: $blue-medium;
$sans-serif: Helvetica, Arial, sans-serif !important;
$serif: 'Times New Roman', serif;

body {
  background-color: #f4f5f4;
  text-align: center;
  font-family: $sans-serif;
  font-size: 11px;

  overflow-x: hidden;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 992px) {
    font-size: 15px;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
  }
}

hr {
  margin-top: em(26);
  margin-bottom: em(26);
  border: 0;
  border-top: 1px solid #dce1e8;
}

.row-fluid [class*='span'] {
  min-height: 0 !important;
}

.overlay,
%overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &--light {
    background: rgba(black, .2);
  }
  &--medium {
    background: rgba(black, .4);
  }
  &--dark {
    background: rgba(black, .6);
  }
}

.constrain,
%constrain {
  float: none !important;
  margin: auto !important;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 1140px;
  box-sizing: border-box;
  &--narrow {
    max-width: 756px;
  }
  &--wide {
    max-width: 1240px;
  }
}

.error-template, .password-template {
  .constrain {
    padding-top: em(45);
    padding-bottom: em(45);
  }
}

.constrain {
  .page--narrow & {
    @extend %constrain--narrow;
  }
  .page--wide & {
    @extend %constrain--wide;
  }
}

.primary-content-container {
  @extend %constrain;
  @extend %constrain--wide;
  position: relative;
  z-index: 3;
  box-shadow: 0 em(2) em(11) rgba(0, 0, 0, .18);
  background-color: white;
  padding-right: 0;
  padding-left: 0;
  text-align: left;
  &:not(.has-breadcrumb) {
    @media (min-width: 992px) {
      margin-top: em(50) !important;
    }
  }
  div[class*='flex'] {
    > span {
      .hs_cos_wrapper_type_rich_text {
        @extend %constrain;
      }
    }
  }
  &--padding-standard {
    padding-top: em(50);
    padding-bottom: em(50);
  }
  &--padding-large {
    padding-top: em(80);
    padding-bottom: em(80);
  }
}

.responsive-video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  iframe, embed {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.interior--one-col {
  .primary-content {
    @extend %constrain;
    margin: auto;
    padding-top: em(43);
    padding-bottom: em(50);
    @media (max-width: 767px) {
      // padding-right: 20px;
      // padding-left: 20px;
    }
  }
}

.interior--one-col-with-flexible {
  
}

.interior--two-col {
  .primary-content {
    @extend %constrain;
    padding-top: em(54);
    padding-bottom: em(54);
    &__left-col {
      @media (max-width: 768px) {
        padding-bottom: em(40);
      }
      @media (min-width: 768px) {
        padding-right: em(130);
      }
    }
  }
}

.primary-content {
  &--two-col {
    @media (min-width: 768px) {
      > .row-fluid-wrapper > .row-fluid {
        display: flex;
        justify-content: space-between;
        [class*='primary-content'] {
          width: 48%;
        }
      }
    }
  }
  &--two-col-right {
    @media (min-width: 768px) {
      > .row-fluid-wrapper > .row-fluid {
        display: flex;
        justify-content: space-between;
      }
      .primary-content__left-col {
        width: 71% !important;
      }
      .primary-content__right-col {
        width: 25% !important;
      }
    }
  }
  &--two-col-left {
    @media (min-width: 768px) {
      > .row-fluid-wrapper > .row-fluid {
        flex-direction: row-reverse;
      }
      .primary-content__left-col {
        width: 71% !important;
      }
      .primary-content__right-col {
        width: 25% !important;
      }
    }
  }
}

%dynamic-menu {
  .hs-menu-depth-1 {
    &:not(.active-branch) {
      display: none;
      visibility: hidden;
    }
  }
}

.side-by-side {
  > div > div {
    display: flex;
    // background-color: #f7f7f7;
    background-color: white;
    @media (max-width: 991px) {
      flex-direction: column;
    }
    @media (min-width: 992px) {
      align-items: stretch;
      margin-bottom: em(50);
    }
    h2 {
      margin-top: em(5, 30);
      margin-bottom: em(31, 30);
    }
    > div {
      @media (min-width: 992px) {
        width: 50%;
        &:first-child {
          background: $blue-dark;
          position: relative;
          // margin-bottom: em(50);
          > span {
            // position: absolute;
            // top:50%;
            // transform: translateY(-50%);
          }
          .testimonial {
            padding: 2em 1.25em;
          }
        }
      }
      &:last-child {
        background-color: #f7f7f7;
        @media (max-width: 991px) {
          padding: em(29);
        }
        @media (min-width: 992px) {
          padding: em(29) em(44) em(29) em(29);
          box-sizing: border-box;
        }
      }
    }
  }
}

.hs-breadcrumb-menu-wrapper {
  width: 100%;
  ul {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    padding-top: em(13, 16);
    padding-bottom: em(9, 16);
    > li {
      padding-top: 0;
      padding-bottom: 0;
      line-height: (22 / 16);
      white-space: nowrap;
      color: $blue-shade;
      font-size: em(16);
      font-weight: 400;
      &.first-crumb {
        padding-left: 0;
      }
      &:not(.first-crumb) {
        padding-left: em(15, 16);
      }
      &:not(.last-crumb) {
        position: relative;
        padding-right: em(15, 16);
        &:after {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%) translateX(50%);
          border-width: 3px 0 3px 6px;
          border-style: solid;
          border-color: transparent transparent transparent $blue-shade;
          width: 0;
          height: 0;
          content: '';
        }
      }
      a {
        transition: .25s color ease-in-out;
        text-decoration: none;
        line-height: (22 / 16);
        color: $blue-medium;
        font-size: em(16);
        font-weight: 700;
        &:hover {
          color: $blue-light;
        }
      }
    }
  }
  .hs-breadcrumb-menu-divider {
    display: none;
  }
}

.desktop-only {
  @media (max-width: 767px) {
    display: none;
  }
}

.mobile-only {
  @media (min-width: 768px) {
    display: none;
  }
}

table {
  width: 100%;
  table-layout: fixed;
  margin-top: em(20);
  td, th {
    border: 1px solid #a1a1a1;
    padding: em(12);
    border-left: 0;
    border-right: 0;
  }
  th {
    background-color: $blue-dark;
    color: white;
    border: 1px solid white;
    border-top: 1px solid $blue-dark;
    border-bottom: 1px solid $blue-dark;
    border-left: 0;
    border-right: 0;
    &:first-child {
      border-left: 1px solid $blue-dark;
    }
    &:last-child {
      border-right: 1px solid $blue-dark;
    }
  }
}

.anchor {
  display: block;
  position: relative;
  top: -60px;
  visibility: hidden;
}

