.individual-event {
  display: flex;
  text-decoration: none;
  align-items: center;
  box-sizing: border-box;
  &:hover {
    .individual-event__content__title {
      color: $blue-dark;
    }
  }
  &__thumbnail {
    margin: 0 em(18) 0 0;
    &__image {
      width: em(99);
      height: auto;
    }
  }
  &__content {
    padding-right: em(50);
    width: calc(100% - 7.3125em);
    &__date,
    &__title,
    &__location {
      display: block;
      text-align: left;
    }
    &__date {
      line-height: (22 / 14);
      color: $gray-dark;
      font-size: em(14);
      font-weight: 700;
    }
    &__title {
      transition: .25s color ease-in-out;
      line-height: (22 / 16);
      color: #0042ba;
      font-size: em(16);
      font-weight: 700;
    }
    &__location {
      line-height: (22 / 16);
      color: #444;
      font-size: em(16);
      font-weight: 700;
    }
  }
}

.individual-events__flexible {
  > span {
    @extend %constrain;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 767px) {
      align-items: center;
      flex-direction: column;
      justify-content: center;
      > div {
        width: 100%;
        margin-bottom:5px;
      }
    }
    @media (min-width: 768px) {
      > div {
        flex-basis: 33.333%;
      }
    }
  }
}

.hs-inline-edit .individual-events__flexible {
  > span {
    flex-direction: column !important;
    > div {
      width: 100% !important;
    }
  }
}
