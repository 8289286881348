.cta-btn,
%cta-btn {
  display: inline-block;
  position: relative;
  transition: .3s ease-in-out;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-size: em(16);
  font-weight: 700;
  span {
    font-size: inherit;
  }
  &--small,
  &--large {
    border: 0;
    border-radius: em(3, 16);
  }
  &--small {
    padding: em(7.25, 16) em(18, 16);
  }
  &--large,
  &--xl {
    padding: em(16.5, 16) em(30, 16);
  }
  &--xl {
    border: em(3) solid white;
    border-radius: em(3, 16);
  }
  &--gold,
  &--blue {
    @extend %cta-btn;
    padding: em(16.5, 16) em(30, 16);
    border-radius: 3px;
    transition: .25s ease-in-out;
    color: white;
    &.cta-btn--small {
      padding: em(7.25, 16) em(18, 16);
    }
  }
  &--gold {
    background-color: $gold;
    &:hover {
      background-color: #f2a400;
      color: white;
    }
  }
  &--blue {
    background-color: #0042ba;
    &:hover {
      background-color: #003493;
      color: white;
    }
  }
  &--white {
    transition: .25s all ease-in-out;
    background-color: white;
    color: #0042ba;
    &:hover {
      background-color: $blue-medium;
      color: white;
    }
  }
  &--arrow {
    position: relative;
    background: transparent;
    padding-right: em(20, 16);
    line-height: (26 / 16);
    color: $gold;
    &:after {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background-image: url('//cdn2.hubspot.net/hubfs/1954929/assets/images/link-arrow.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      width: em(13, 16);
      height: em(10, 16);
      content: '';
    }
    &:hover {
      padding-right: em(26, 16);
      color: #f2a400;
    }
  }
}

.cta_button {
  @extend %cta-btn;
  @extend %cta-btn--large;
  @extend %cta-btn--blue;
}
