// 01. Rich Text
.hs_cos_wrapper_type_rich_text {}

// 02. One Line of Text
.hs_cos_wrapper_type_text {
  margin-bottom: 1em;
}

// 03. Page Footer
.hs_cos_wrapper_type_page_footer {}

// 04. Logo
.hs_cos_wrapper_type_logo {}

// 05. Image
.hs_cos_wrapper_type_linked_image {}

// 06. Image Slider
.hs_cos_flex-slider {
  .hs_cos_flex-control-paging {
    margin: 1em 0;
    padding: 0;
    list-style: none;
    text-align: center;
    li {
      display: inline-block;
      margin: 0 em(5);
    }
    a {
      display: block;
      border-radius: 50%;
      background-color: $gray;
      cursor: pointer;
      width: em(12);
      height: em(12);
      overflow: hidden;
      text-indent: -999px;
      &[class*='active'] {
        background-color: $primary;
      }
    }
  }
  .hs_cos_flex-direction-nav {
    margin: 1em 0;
    padding: 0;
    list-style: none;
    text-align: center;
    li {
      display: inline-block;
      position: relative;
      margin: 0 em(15);
      a {
        display: block;
        position: relative;
        text-transform: uppercase;
        letter-spacing: 1px;
        &:before {
          display: inline-block;
          position: absolute;
          top: 48%;
          transform: translateY(-50%);
          transition: color .3s;
          border-style: solid;
          width: 0;
          height: 0;
          content: '';
        }
      }
      &:first-child {
        a {
          padding-left: em(20);
          &:before {
            left: 0;
            border-width: em(7) em(10) em(7) 0;
            border-color: transparent $primary transparent transparent;
          }
        }
      }
      &:last-child {
        a {
          padding-right: em(20);
          &:before {
            right: 0;
            border-width: em(7) 0 em(7) em(10);
            border-color: transparent transparent transparent $primary;
          }
        }
      }
    }
  }
  .hs_cos_flex-slides-thumb {
    margin: 0;
    padding: 0;
  }
  &.slider-mode-slider {
    .hs_cos_flex-slides-main {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    .hs_cos_flex-slide-main {
      img {
        width: 100%;
        height: auto;
      }
    }
    .hs_cos_flex-slides-thumb {
      li {
        margin-right: em(10);
      }
    }
    .hs_cos_flex_thumbnavs-direction-nav {
      display: flex;
      margin: 1em 0;
      padding: 0;
      width: 100%;
      list-style: none;

      justify-content: space-between;
      li {
        position: relative;
        a {
          outline: none;
          text-transform: uppercase;
          letter-spacing: 1px;
          &:before {
            display: inline-block;
            position: absolute;
            top: 48%;
            transform: translateY(-50%);
            transition: color .3s;
            border-style: solid;
            width: 0;
            height: 0;
            content: '';
          }
        }
        &:first-child {
          a {
            padding-left: em(20);
            &:before {
              left: 0;
              border-width: em(7) em(10) em(7) 0;
              border-color: transparent $primary transparent transparent;
            }
          }
        }
        &:last-child {
          a {
            padding-right: em(20);
            &:before {
              right: 0;
              border-width: em(7) 0 em(7) em(10);
              border-color: transparent transparent transparent $primary;
            }
          }
        }
      }
    }
  }
  &.slider-mode-gallery {}
}

// 07. Header
.hs_cos_wrapper_type_header {}

// 08. Section Header
.hs_cos_wrapper_type_section_header {}

// 09. Form
.hs_cos_wrapper_type_form {}

// 10. Social Sharing
.hs_cos_wrapper_type_social_sharing {}

// 11. Follow Me
.hs_cos_wrapper_type_follow_me {}

// 12. Post Listing
.hs_cos_wrapper_type_post_listing {
  h3 {}
  .widget-module {}
}

// 13. Post Filter
.hs_cos_wrapper_type_post_filter {
  h3 {
    @extend %h4;
    @extend %header-underline;
  }
  .widget-module {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        margin-top:em(15);
        margin-bottom:em(15);
      }
      a {
        color: $gray-dark;
        font-size: em(18);
        font-weight: 400;
        line-height: 1.25;
        text-decoration: none;
        transition: 0.25s color ease-in-out;
        &:hover {
          color: $blue-light;
        }
      }
      .filter-link-count {
        display: none;
      }
    }
    .filter-expand-link {
      color: #0042ba;
      font-size: em(16);
      font-weight: 700;
      line-height: (33.6 / 16);
      transition: 0.25s color ease-in-out;
      text-decoration: none;
      &:hover {
        color: $blue-light;
      }
    }
  }
}

// 14. Blog Email Subscription
.hs_cos_wrapper_type_blog_subscribe {
  h3 {
    @extend %h4;
    @extend %header-underline;
  }
  .hs-form ul.inputs-list {
    display: flex;
    flex-wrap: wrap;
    li {
      flex-basis: 50%;
      margin: em(6.5) 0;
    }
  }
  label > span {
    color: $blue-dark;
    font-size: em(16);
    font-weight: 700;
    line-height: (22.4 / 16);
  }
}

// 15. Call-to-Action
.hs_cos_wrapper_type_cta {}

// 16. Custom HTML
.hs_cos_wrapper_type_raw_html {}

// 17. Google Search
.hs_cos_wrapper_type_google_search {
  #hs-search-module {
    label {
      @extend %h4;
      display: block;
    }
    .hs-button {
      @extend %cta-btn;
      margin-top: 1em;
      cursor: pointer;
    }
  }
}

// 18. Horizontal Spacer
.hs_cos_wrapper_type_space {}

// 19. RSS Listing
.hs_cos_wrapper_type_rss_listing {}
