.site-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
  }
  &__top {
    background-color: #003087;
    padding: em(22) 0 em(20.5);
    .site-header__inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  &__bottom {
    background-color: #002855;
    font-size: 16px;
    .site-header__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    svg {
      max-height: 19px;
    }
  }
  &__inner {
    @extend %constrain;
  }
  a {
    display: block;
    text-decoration: none;
  }
  figure {
    margin: 0;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
    }
  }
  @media (min-width: 992px) {
    padding: 0;
  }
  &__logo {
    position: relative;
    width: 100%;
    max-height: 65px;
    text-align: left;
    margin: 0;
    &:after {
      display: block;
      position: absolute;
      right: 0;
      bottom: em(18);
      background-color: white;
      height: 1px;
      content: '';
      @media (max-width: 449px) {
        width: calc(100% - 5.4em);
      }
      @media (min-width: 450px) and (max-width: 767px) {
        width: calc(100% - 6em);
      }
      @media (min-width: 768px) and (max-width: 1199px) {
        width: calc(100% - 4.8em);
      }
      @media (min-width: 1200px) {
        width: calc(100% - 3.85em);
      }
    }
    img {
      width: em(453);
      height: auto;
    }
  }
  &__secondary-logo {
    margin-top: em(-9) !important;
    width: 100%;
    text-align: right;
    @media (max-width: 767px) {
      display: none;
    }
    img {
      height: em(34);
      width: auto;
    }
  }
  &__home-link {
    margin-right: em(15);
    svg {
      width: em(20);
      height: auto;
    }
    svg path {
      transition: .25s fill ease-in-out;
      fill: white;
    }
    &:hover {
      svg path {
        fill: $blue-light;
      }
    }
  }
  &__main-menu {
    position: relative;
    text-align: left;

    flex-grow: 1;
    @media (max-width: 991px) {
      display: none;
    }
    &:before {
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-color: white;
      width: 1px;
      height: em(20);
      content: '';
    }
    .hs-menu-depth-1 {
      margin-right: em(12.5);
      margin-left: em(12.5);
      // &:last-child {
      //   display: none;
      // }
      &:hover > a {
        color: $blue-light;
      }
      > a {
        transition: .25s color ease-in-out;
        padding: em(15, 18) 0;
        line-height: (22 / 18);
        color: white;
        font-size: em(18);
        font-weight: 700;
      }
      &:hover {
        .hs-menu-children-wrapper {
          display: block;
        }
      }
      &.hs-item-has-children {
        &:hover > a {
          &:after {
            border-color: $blue-light transparent transparent;
          }
        }
        > a {
          position: relative;
          padding-right: em(18, 18);
          &:after {
            display: inline-block;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-25%);
            transition: .25s border-color ease-in-out;
            border-width: em(6, 18) em(5.5, 18) 0;
            border-style: solid;
            border-color: rgba($gray-lighter, .5) transparent transparent;
            width: 0;
            height: 0;
            content: '';
          }
        }
        li {
          position: relative;
          &:hover {
            > .hs-menu-children-wrapper {
              display: block;
              top: 0;
              left: 100%;
            }
          }
          .hs-menu-children-wrapper {
            display: none;
            &:before {
              display: none;
            }
          }
        }
      }
      > .hs-menu-children-wrapper {
        transform: translateX(-1.8em);
      }
    }
    .hs-menu-depth-3 {
      a {
        padding: em(7, 15) em(20, 15) !important;
        line-height: (18 / 15);
        font-size: em(15) !important;
      }
    }
    .hs-menu-children-wrapper {
      display: none;
      position: absolute;
      box-shadow: 0 em(2) em(4) 0 rgba(0, 0, 0, .5);
      background-color: #fefefe;
      padding: 0;
      text-align: left;
      &:before {
        display: inline-block;
        position: absolute;
        top: em(-8);
        left: 50%;
        transform: translateX(-50%);
        border-width: 0 em(9.5) em(8);
        border-style: solid;
        border-color: transparent transparent #fefefe;
        width: 0;
        height: 0;
        content: '';
      }
      li {
        display: block;
        &:hover > a {
          background-color: #dde2eb;
          color: #0042ba;
        }
        &.hs-item-has-children {
          &:hover > a {
            &:after {
              border-color: transparent transparent transparent #0042ba;
            }
          }
          > a {
            position: relative;
            padding-right: em(60, 16) !important;
            &:after {
              display: inline-block;
              position: absolute;
              top: 50%;
              right: em(28, 16);
              transform: translateY(-50%);
              transition: .25s border-color ease-in-out;
              border-width: em(6.5, 16) 0 em(6.5, 16) em(6, 16);
              border-style: solid;
              border-color: transparent transparent transparent rgba(#0042ba, .5);
              width: 0;
              height: 0;
              content: '';
            }
          }
        }
      }
      a {
        transition: .25s all ease-in-out;
        padding: em(7.5, 16) em(28, 16);
        white-space: nowrap;
        color: $blue-medium;
        font-size: em(16);
        font-weight: 700;
      }
    }
  }
  &__touch-only {
    @media (max-width: 767px) {
      padding: em(11) 0 em(11) em(15);
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding: em(16.5) 0 em(16.5) em(15);
    }
    @media (max-width: 991px) {
      position: relative;
      text-align: left;
      flex-grow: 1;
      &:before {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background-color: white;
        width: 1px;
        height: em(20);
        content: '';
      }
    }
    @media (min-width: 992px) {
      display: none;
    }
  }
  &__menu-trigger {
    display: flex;
    border: 0;
    background: transparent;
    padding: 0;

    align-items: center;
    &,
    &:focus {
      outline: none;
    }
    &__icon {
      margin-right: em(8);
      width: em(20);
      height: auto;
    }
    &__title {
      margin-top: em(1, 16);
      vertical-align: middle;
      line-height: (19 / 16);
      color: white;
      font-size: em(16);
      font-weight: 700;
    }
  }
  &__secondary-menu {
    position: relative;
    @media (min-width: 768px) {
      padding-right: em(15);
      &:after {
        // display: block;
        // position: absolute;
        // top: 50%;
        // right: 0;
        // transform: translateY(-50%);
        // background-color: white;
        // width: 1px;
        // height: em(20);
        // content: '';
      }
    }
    .hs-menu-depth-1 {
      margin-right: em(7.5);
      margin-left: em(7.5);
      > a {
        transition: .25s color ease-in-out;
        line-height: (17 / 14);
        color: white;
        font-size: em(14);
        font-weight: 700;
        @media (min-width: 768px) {
          text-transform: uppercase;
        }
        &:hover {
          color: $blue-light;
        }
      }
    }
  }
  &__client-login {
    display: flex;
    transition: .25s color ease-in-out;
    margin-left: em(15);
    text-transform: uppercase;
    line-height: (15 / 14);
    color: white;
    font-size: em(14);
    font-weight: 700;
    align-items: center;
    @media (max-width: 767px) {
      display: none !important;
    }
    svg {
      margin: em(-2.5, 13) em(5, 13) 0 0;
      width: em(13);
      height: auto;
    }
    svg path {
      transition: .25s fill ease-in-out;
      fill: white;
    }
    &:hover {
      text-transform: uppercase;
      color: $blue-light;
      svg path {
        fill: $blue-light;
      }
    }
  }
}

body {
  &.lp {
    .site-header__bottom {
      display: none;
    }
  }
}
