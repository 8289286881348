.individual-case-study {
  display: flex;
  margin-bottom: em(23);
  align-items: center;
  box-sizing: border-box;
  &--small,
  &--boxed {
    .individual-case-study {
      &__thumbnail {
        margin: 0 em(26) 0 0;
      }
    }
  }
  &--small {
    .individual-case-study {
      &__thumbnail {
        margin-right: em(26);
        border: 1px solid #dce1e8;
        background-color: white;
        padding: em(6);
      }
    }
  }
  &--boxed {
    border: 1px solid #bbc5d7;
    box-shadow: 0 em(2) em(4) rgba(0, 0, 0, .2);
    padding: em(30);
  }
  &--full {
    margin-bottom: 0;
    .individual-case-study {
      align-items: strech;
      @media (max-width: 767px) {
        flex-direction: column;
      }
      &__content {
        padding: em(40) em(52);
        flex-shrink: 3;
        &__title {
          @extend %h2;
          padding-bottom:em(30, 30);
          margin-top: em(16, 30);
          margin-bottom:em(27, 30);
        }
        &__type {
          font-size: em(18);
        }
      }
      &__thumbnail {
        margin: 0;
        width: 47%;
        &__image {
          object-fit: cover;
        }
      }
    }
  }
  &__thumbnail {
    width: em(148);
    &__image {
      width: 100%;
      height: auto;
    }
  }
  &__content {
    width: calc(100% - 10.875em);
    &__type,
    &__title,
    &__link {
      display: block;
      text-align: left;
    }
    &__type {
      margin-bottom: em(2, 16);
      text-transform: uppercase;
      line-height: (22 / 16);
      color: $blue-shade;
      font-size: em(16);
      font-weight: 700;
    }
    &__title {
      margin-bottom: em(6, 22);
      border-bottom: 1px solid #dce1e8;
      padding-bottom: em(8, 22);
      width: 100%;
      line-height: (27 / 22);
      color: $blue-dark;
      font-size: em(22);
      font-weight: 700;
    }
    &__link {
      text-decoration: none;
      line-height: (30 / 16);
      color: #0042ba;
      font-size: em(16);
      font-weight: 700;
    }
  }
}

.case-studies__flexible {
  > span {
    @extend %constrain;
    display: flex;
    border-top: 1px solid #dce1e8;
    padding-top: em(44);
    padding-bottom: em(30);
    align-items: flex-start;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    @media (min-width: 1200px) {
      padding-right: 0;
      padding-left: 0;
    }
    > div {
      width: 100%;
      @media (min-width: 768px) {
        &:first-child {
          margin-right: em(15);
        }
        &:last-child {
          margin-left: em(15);
        }
      }
    }
  }
}

.hs-inline-edit .case-studies__flexible {
  > span {
    flex-direction: column !important;
    > div {
      margin-right: 0 !important;
      margin-left: 0 !important;
      width: 100% !important;
    }
  }
}
