%module,
.module {
  @extend %constrain;
  &--spacer {
    @extend %constrain;
    padding-right: 0;
    padding-left: 0;
  }
  &--gray {
    background-color: $gray-light;
  }
  &--small {
    padding-top: em(40);
    padding-bottom: em(40);
  }
  &--medium {
    padding-top: em(60);
    padding-bottom: em(60);
  }
  &--large {
    padding-top: em(80);
    padding-bottom: em(80);
  }
  &--no-top {
    padding-top: 0;
  }
  &--no-bottom {
    padding-bottom: 0;
  }
  &__section-header {
    margin-bottom: em(30);
  }
  &__section-body {}
  &__section-footer {}
  &--no-side-padding {
    padding-left: 0;
    padding-right: 0;
  }
}
