.nested-layout {
  @extend %constrain;
  &__grid {
    &,
    span {
      @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        &.nested-layout--main {
          flex-wrap: wrap;
        }
        &.nested-layout--r-to-l {
          flex-direction: row-reverse;
        }
      }
    }
  }
  &__content {}
  &__photo {
    img {
      width: 100%;
    }
  }
  &__column {
    position: relative;
    &--card {
      padding: em(23) em(29);
      background-color: white;
      border: 1px solid #bbc5d7;
      border-radius: em(4);
      box-shadow: 0 em(2) em(4) rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      @media (max-width:767px) {
        margin-bottom: em(20);
      }
      .inner {
        > :last-child {
          margin-bottom: 0;
        }
        a {
          color: #0042ba;
          font-size: em(16);
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
  }
  @media (min-width: 768px) {
    &--one-col {
      span > div, > a,
      .nested-layout__column {
        width: 100%;
      }
    }
    &--two-col {
      span > div, > a,
      .nested-layout__column {
        width: calc(50% - 15px);
      }
    }
    &--three-col {
      span > div, > a,
      .nested-layout__column {
        width: calc(33.3% - 15px);
      }
    }
    &--four-col {
      span > div, > a,
      .nested-layout__column {
        width: calc(25% - 15px);
      }
    }
  }
  &--hide {
    display: none;
    visibility: hidden;
  }
}
