.section-header {
  &__inner {
    padding-top: em(50);
    padding-bottom: em(42);
    text-align: center;
    &.no-top {
      padding-top: 0;
    }
  }
  h2,
  h3,
  h5 {
    display: block;
    text-align: left;
  }
  h2 {
    margin-top: 0;
    margin-bottom: em(9, 30);
  }
  h5 {
    margin-top: 0;
    margin-bottom: 0;
  }
}
