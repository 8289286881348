.product-service-box {
  display: block;
  transition: .25s all ease-in-out;
  margin: em(10.5);
  border-radius: em(4);
  padding: em(24) em(17);
  text-align: center;
  text-decoration: none;
  @media (max-width: 767px) {
    border: 1px solid #bbc5d7;
    box-shadow: 0 em(2) em(4) rgba(0, 0, 0, .2);
  }
  @media (min-width: 768px) {
    border: 1px solid transparent;
    box-shadow: 0 em(2) em(4) rgba(0, 0, 0, 0);
  }
  &:hover {
    border: 1px solid #bbc5d7;
    box-shadow: 0 em(2) em(4) rgba(0, 0, 0, .2);
  }
  &__icon {
    position: relative;
    margin: 0 auto em(21);
    width: em(83);
    height: em(77);
    text-align: center;
    &__image {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      transform: translateY(-50%);
      margin: auto;
      object-fit: contain;
    }
  }
  &__title {
    display: block;
    text-align: center;
    line-height: (27 / 22);
    color: $blue-dark;
    font-size: em(22);
    font-weight: 700;
  }
}

.product-service-boxes__flexible {
  .interior--two-col & {
    padding-top: em(20);
    padding-bottom: 0;
    > span {
      border-bottom: 1px solid #dce1e8;
      padding-bottom: em(17);
    }
  }
  > span {
    @extend %constrain;
    display: flex;
    text-align: center;
    align-items: flex-start;
    @media (min-width: 768px) {
      justify-content: center;
    }
    @media (max-width: 991px) {
      flex-wrap: wrap;
      display: -webkit-flex;
      -webkit-box-orient: horizontal; 
      -webkit-box-direction: normal; 
      -webkit-flex-direction: row; 
      -webkit-flex-wrap: wrap;
    }
    > div {
      @media (max-width: 767px) {
        width: 50%;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        flex-basis: 33.3333%;
      }
    }
  }
  &.products-wrap {
    @media(min-width:768px) {
      > span {
        flex-wrap: wrap;
        justify-content: flex-start;
        > div {
          width: 20%;
        }
      }
    }
  }
}

.hs-inline-edit .product-service-boxes__flexible {
  > span {
    flex-direction: column !important;
    > div {
      width: 100% !important;
    }
  }
}
