input[type='text'],
input[type='email'],
input[type='phone'],
input[type='tel'],
input[type='number'],
input[type='file'],
textarea,
.hs_dropdown_select .input {
  transition: .25s all ease-in-out;
  outline: none;
  border: 1px solid $blue-shade;
  padding: 10px;
  width: 100%;
  color: $blue-dark;
  font-size: em(16);
  box-sizing: border-box;
  box-shadow: 0 em(2) em(2) rgba(0, 0, 0, 0);
  &:focus {
    border: 1px solid #0042ba;
    color: #0042ba;
    box-shadow: 0 em(2) em(2) rgba(0, 0, 0, 0.1);
  }
}

input[type='file'] {
  padding-top: 11px;
  padding-bottom: 11px;
  line-height: 1ex;
  font-size: 1em;
}

select {
  font-size: inherit;
}

input[type='submit'] {
  @extend %cta-btn--gold;
  @extend %cta-btn--large;
  @extend %cta-btn;
}

.form-title {
  @extend %h4;
  @extend %header-underline;
  &:not(:empty) {
    margin-bottom: 1em;
  }
}

.hs-form {
  margin-bottom:em(17);
  fieldset {
    max-width: none;
  }
  .hs-form-field {
    margin-bottom: 1em;
    vertical-align: top;
    > label {
      display: block;
      margin-bottom: em(10);
      line-height: (22.4 / 16);
      color: $blue-dark;
      font-size: em(16);
      font-weight: 700;
    }
  }
  .input {
    width: 100%;
  }
  .styled-select {
    position: relative;
    overflow: hidden;
    border: 1px solid $blue-shade;
    select {
      outline: none;
      border: 0;
      background: transparent;
      width: 103% !important;
      font-size: inherit;
      appearance: none;
      box-sizing: border-box;
      padding: 11px 10px;
      color: $blue-dark;
      &:focus {
        color: #0042ba;
      }
    }
    &:before {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      border-width: em(7) em(5) 0;
      border-style: solid;
      border-color: $primary transparent transparent;
      width: 0;
      height: 0;
      content: '';
    }
  }
  .hs-datepicker {
    transform: translateY(-1px);
    .pika {
      &-single {
        border: 1px solid $gray;
        box-shadow: none;
      }
      &-label {
        color: $gray-darker;
        font-size: 1em;
        font-weight: normal;
      }
      &-lendar {
        margin: 0;
        padding: 10px;
      }
      &-button {
        border-radius: 0;
        box-shadow: none;
        padding: 10px 5px;
        text-align: center;
        font-size: em(13);
        &:hover {
          border-radius: 0 !important;
          background-color: rgba($primary, .75) !important;
        }
      }
      &-table {
        th {
          font-size: em(13);
        }
        td {
          &:not(.is-today):not(.is-selected) .pika-button {
            background-color: #fff;
            color: $gray-dark;
          }
          &.is-today .pika-button {
            background-color: $gray-lighter;
            color: $primary;
          }
        }
      }
    }
    .is-selected {
      .pika-button {
        background-color: $primary;
        font-weight: normal;
        &:hover {
          background-color: $primary !important;
        }
      }
    }
    abbr {
      border-bottom: 0;
      text-decoration: none;
      color: $gray-darker;
      font-weight: normal;
    }
  }
  .hs-error-msgs {
    line-height: (22.4 / 16);
    color: #d0021b;
    font-family: Helvetica;
    font-size: em(16);
    font-weight: 700;
  }
  ul.inputs-list {
    margin-top: 10px;
    margin-left: 0;
    padding-left: 0;
    list-style: none;
    input {
      margin-right: em(10);
    }
  }
  .hs-button {
    margin-top:em(18);
    @extend %cta-btn;
    @extend %cta-btn--large;
    @extend %cta-btn--gold;
  }
}

// Hubspot Overrides
fieldset {
  .hs-input:not([type='checkbox']):not([type='radio']):not(select) {
    width: 100% !important;
  }
  > div {
    float: none !important;
  }
  @media (min-width: 768px) {
    &.form-columns-2 {
      > div {
        display: inline-block;
        float: none !important;
        width: 49% !important;
        &:last-child {
          float: right !important;
        }
      }
    }
    &.form-columns-3 {
      text-align: center;
      > div {
        display: inline-block;
        margin: 0 1%;
        width: 32% !important;
        text-align: left;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          float: right !important;
          margin-right: 0;
        }
      }
    }
  }
  @media (max-width: 767px) {
    > div {
      display: block !important;
      width: 100% !important;
    }
  }
}
