.sidebar {
  > div:not(:last-child) {
    margin-bottom: 2em;
  }
}

.sidebar-menu {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
      > ul {
        margin-top: em(12);
        border-top: 1px solid #dce1e8;
        padding-top: em(17);
        a {
          line-height: (30 / 18);
          color: $gray-dark;
          font-size: em(18);
          font-weight: 400;
        }
        li {
          padding-bottom: em(20);
          &.active > a {
            color: #0042ba;
            font-weight: 700;
          }
        }
      }
      > a {
        line-height: (27 / 22);
        color: $blue-dark;
        font-size: em(22);
        font-weight: 700;
      }
    }
  }
  a {
    transition: .25s color ease-in-out;
    text-decoration: none;
    &:hover {
      color: $blue-light !important;
    }
  }
  ul {
    margin-left: 0 !important;
    padding: 0;
  }
  .hs-menu-children-wrapper {
    margin-left: em(10);
    font-size: em(14);
  }
  &--static {}
  &--dynamic {
    @extend %dynamic-menu;
    .hs-menu-depth-2 {
      > ul {
        // display: none;
        padding-left: em(20);
        border-top: 0;
      }
      &.active-branch  {
        > ul {
          display: block;
          padding-left: em(20);
          border-left: 2px solid #0042ba;
        }
      }
    }
  }
}
