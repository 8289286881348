.blog {
  &__header {
    margin-bottom: em(30);
    border-bottom: 1px solid #dce1e8;
    .custom-sharing {
      padding-top: 0;
    }
    .post__author {
      display: block;
      margin-top: em(8,16);
      margin-bottom: em(14,16);
      font-size: em(16);
    }
    .blog__share-buttons {
      padding-bottom: em(14);
    }
  }
  &__share {
    border-top: 1px solid #dce1e8;
    padding: em(30) 0 em(5);
    display: flex;
    align-items: center;
    h4 {
      margin: 0 em(47,22) 0 0;
    }
    .hs-blog-social-share {
      margin-top: em(-10);
    }
  }
  &__share-buttons {
    display: flex;
  }
  &__topics {
    display: flex;
    padding: 0 0 em(15);
    align-items: center;
    width: 100%;
    h3 {
      @extend %h4;
      margin: 0;
    }
    li {
      line-height: (27 / 22);
      color: $gray-dark;
      font-size: em(16);
      font-weight: 400;
      a {
        text-decoration: none;
        color: $gray-dark;
        font-size: em(22,16);
        &:hover {
          color: $blue-light;
        }
      }
    }
  }
  &__cta {
    margin: em(30) 0;
    img {
      width: 100%;
    }
  }
  &__pagination {
    display: flex;
    position: relative;
    margin: em(50) auto 0;
    max-width: 160px;
    justify-content: space-between;
    @media (max-width: 991px) {
      margin-bottom: em(61);
    }
    a {
      text-transform: uppercase;
      text-decoration: none;
      line-height: (19 / 16);
      color: $blue-medium;
      font-size: em(16);
      font-weight: 700;
      &.disabled {
        color: $blue-shade;
        pointer-events: none;
      }
      &:not(.disabled) {
        &:hover {
          color: $blue-light;
        }
      }
    }
    &--prev {
      &:before {
        display: inline-block;
        transition: .25s border-color ease-in-out;
        margin-right: em(7);
        border-width: em(6) em(8) em(6) 0;
        border-style: solid;
        border-color: transparent $blue-medium transparent transparent;
        width: 0;
        height: 0;
        content: '';
      }
      &.disabled {
        &:before {
          border-color: transparent $blue-shade transparent transparent;
        }
      }
      &:not(.disabled):hover {
        &:before {
          border-color: transparent $blue-light transparent transparent;
        }
      }
    }
    &--next {
      &:after {
        display: inline-block;
        transition: .25s border-color ease-in-out;
        margin-left: em(7);
        border-width: em(6) 0 em(6) em(8);
        border-style: solid;
        border-color: transparent transparent transparent $blue-medium;
        width: 0;
        height: 0;
        content: '';
      }
      &.disabled {
        &:after {
          border-color: transparent transparent transparent $blue-shade;
        }
      }
      &:not(.disabled):hover {
        &:after {
          border-color: transparent transparent transparent $blue-light;
        }
      }
    }
  }
  &__related-posts {
    margin-top: em(30);
    border-top: 1px solid #dce1e8;
    padding-top: em(39);
    padding-bottom: em(39);
    h4 {
      margin-bottom: em(18,22);
    }
    &__inner {
      display: flex;
      @media (max-width:767px) {
        flex-direction: column;
      }
      @media (min-width:768px) {
        justify-content: space-between;
      }
      .post {
        @media (min-width:768px) {
          flex-basis: calc(50% - 14px);
        }
        &:not(:first-child):not(:nth-child(2)) {
          display: none;
        }
      }
    }
  }
  &__comments {
    border-top: 1px solid #dce1e8;
    padding: em(30) 0;
    &:before {
      @extend %h4;
      display: block;
      margin-bottom: em(15);
      font-weight: 700;
      content: 'Comments';
    }
    #comment-form {
      &:before {
        @extend %h4;
        display: block;
        margin-bottom: em(10);
        color: $blue-medium;
        font-size: em(24);
        font-weight: 700;
        content: 'Leave a Comment';
      }
      .actions {
        text-align: left;
      }
      textarea {
        min-height: em(137);
      }
    }
    #captcha_wrapper {
      width: 100%;
      overflow: hidden;
      @media (max-width:767px) {
        transform: scale(.9);
      }
    }
  }
}

.author {
  margin-bottom: em(30);
  border: 1px solid #bbc5d7;
  border-radius: em(4);
  box-shadow: 0 em(2) em(4) rgba(0, 0, 0, .2);
  background-color: white;
  padding: em(40);
  text-align: center;
  @media (min-width: 768px) {
    display: flex;
    text-align: left;
    justify-content: space-between;
  }
  a {
    text-decoration: none;
    color: $blue-dark;
    &:hover {
      color: $blue-light;
    }
  }
  svg path {
    fill: #4b89cd;
  }
  &__photo {
    display: inline-block;
    margin-bottom: em(20);
    width: em(150);
    img {
      margin-bottom: 0;
      width: 100%;
      height: auto;
    }
    @media (min-width: 768px) {
      padding-right: em(50);
      flex-basis: 25%;
      img {
        display: block;
        max-width: none;
      }
    }
  }
  &__bio {
    @media (min-width: 768px) {
      flex-basis: 75%;
    }
  }
  &__info {
    margin-bottom: em(10);
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      h4 {
        margin: 0;
      }
    }
  }
  &__name {
    @media (min-width: 768px) {
      margin-right: em(15);
    }
  }
  &__social-links {
    padding: 0;
  }
}

.share-button {
  padding: em(9) em(10);
  display: flex;
  align-items: center;
  color: white;
  margin-right: em(10);
  transition: 0.25s background-color ease-in-out;
  &, &:hover {
    text-decoration: none;
    color: white;
  }
  &--gray {
    background-color: #3E4140;
    &:hover {
      background-color: darken(#3E4140, 10%);
    }
  }
  &--light-blue {
    background-color: #247CB4;
    &:hover {
      background-color: darken(#247CB4, 10%);
    }
  }
  &--dark-blue {
    background-color: #003087;
    &:hover {
      background-color: darken(#003087, 10%);
    }
  }
  &--facebook {
    background-color: #3B5998;
    &:hover {
      background-color: darken(#3B5998, 10%);
    }
  }
  &--twitter {
    background-color: #00aced;
    &:hover {
      background-color: darken(#00aced, 10%);
    }
  }
  &--google {
    background-color: #d34836;
    &:hover {
      background-color: darken(#d34836, 10%);
    }
  }
  i {
    font-size:em(18);
    margin-right:em(10,18);
  }
  span {
    font-size:em(16);
    font-weight: bold;
  }
}

.hs-blog-header {
  border-bottom: em(6) solid $blue-medium;
  background-color: #f7f7f7;
  padding: em(28) 0;
  a {
    text-decoration: none;
    color: $blue-dark;
    &:hover {
      color: $blue-light;
    }
  }
  h1 {
    @extend %constrain;
    margin: 0;
    font-size: em(22);
  }
}

.blog-content-wrapper {
  padding: em(38) em(20) em(61);

  @extend %constrain;
  > div > div {
    display: flex;
    @media (max-width:991px) {
      flex-direction: column;
    }
    @media (min-width:992px) {
      > div {
        &:first-child {
          padding-right: em(60);
          flex-basis: 73.25%;
        }
        &:last-child {
          padding-right: em(30);
          flex-basis: 26.75%;
        }
      }
    }
  }
}

.hs-blog-social-share {
  .IN-widget {
    margin-top: 4px;
  }
  .hs-blog-social-share-item-facebook {
    margin-top: -3px;
  }
  .hs-blog-social-share-item-google-plus {
    margin-top: -4px;
  }
}

.blog-sidebar {
  > div {
    margin-bottom: em(55);
  }
}
