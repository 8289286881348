.custom-sharing {
  padding-top: em(20);
  padding-bottom: em(20);
  text-align: center;
  h4 {
    margin: 0 em(10) 0 0;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
      vertical-align: top;
      a {
        display: block;
        position: relative;
        transition: .3s;
        opacity: .8;
        width: 30px;
        height: 30px;
        color: #fff;
        &[data-type='facebook'] {
          background-color: #3b5998;
        }
        &[data-type='twitter'] {
          background-color: #55acee;
        }
        &[data-type='linkedin'] {
          background-color: #0077b5;
        }
        &[data-type='pinterest'] {
          background-color: #cb2027;
        }
        &[data-type='google-plus'] {
          background-color: #dd4b39;
        }
        &[data-type='email'] {
          background-color: $primary;
        }
        &:hover {
          opacity: 1;
        }
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
}
