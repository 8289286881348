body.lp {
  .site-header {
    &__main-menu,
    &__secondary-menu,
    .menu-trigger__container,
    .mobile-menu {
      display: none;
    }
  }
  .site-footer {
    &__main-menu {
      display: none;
    }
  }
  h2 {
    margin-bottom:em(18, 30);
  }
  .primary-content-container {
    border-bottom: em(30) solid $blue-medium;
  }
  .primary-content--two-col-right .primary-content {
    @media (max-width: 767px) {
      &__left-col {
        margin-bottom: em(60);
      }
    }
    @media (min-width: 768px) {
      &__left-col {
        width: 60% !important;
        padding-right: em(32);
      }
      &__right-col {
        width: 40% !important;
      }
    }
    &__right-col {
      padding: em(22) em(30);
      background-color: white;
      border: 1px solid #bbc5d7;
      box-shadow: 0 em(2) em(4) rgba(0, 0, 0, 0.2);
      .hs_cos_wrapper_type_form {
        padding-bottom: em(11);
        border-bottom: 1px solid #dce1e8;
        margin-bottom: em(5);
      }
    }
  }
}
