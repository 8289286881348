.cta-bar {
  position: relative;
  background-color: $blue-medium;
  overflow: hidden;
  margin-right: 0 !important;
  margin-left: 0 !important;
  max-width: 100%;
  a:not([class*='cta-btn']) {
    color: white;
    &:hover {
      color: $blue-light;
    }
  }
  [class*='constrain'] {
    padding-top: em(30);
    padding-bottom: em(30);
    width: 80%;
  }
  &__underlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center 15%;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  &__content {
    display: flex;
    position: relative;
    align-items: center;
    @media (max-width: 991px) {
      flex-direction: column;
    }
    h2 {
      margin: 0;
      color: white;
      flex-grow: 1;
      @media (min-width: 992px) {
        text-align: right;
      }
    }
    p {
      margin-bottom: 0;
      line-height: 1.3;
      @media (max-width: 991px) {
        margin-top: em(20, 17);
      }
      @media (min-width: 992px) {
        margin-left: em(33, 17);
      }
    }
  }
  &--small {
    @media (min-width: 480px) {
      [class*='constrain'] {
        padding-top: em(30) !important;
        padding-bottom: em(30) !important;
      }
    }
  }
  &--medium {
    @media (min-width: 480px) {
      [class*='constrain'] {
        padding-top: em(100) !important;
        padding-bottom: em(100) !important;
      }
    }
  }
  &--large {
    @media (min-width: 480px) {
      [class*='constrain'] {
        padding-top: em(120) !important;
        padding-bottom: em(120) !important;
      }
    }
  }
  .cta-btn {
    @extend %cta-btn--white;
    @extend %cta-btn--xl;
    @extend %cta-btn;
  }
}
