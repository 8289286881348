$menu-width: 240px;

body.menu-open {
  overflow: hidden;
  .site-header {
    border-bottom: 1px solid #4d6888;
  }
}

.menu-trigger__container {
  &:not(.open) {
    .on {
      display: none;
    }
  }
  &.open {
    .off {
      display: none;
    }
  }
  @media (min-width: 992px) {
    display: none;
    visibility: hidden;
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  transition: .25s border-top-width ease-in-out;
  z-index: 3;
  border-top-style: solid;
  border-top-color: $blue-dark;
  background-color: $blue-dark;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  @media (min-width: 992px) {
    display: none;
  }
  .inner {
    width: 100%;
    text-align: left;
  }
  &__main {
    overflow-y: scroll;
    > ul:last-child {
      padding-bottom: 22px;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      &.hs-menu-depth-1 {
        > a {
          padding-top: 18px;
          padding-bottom: 18px;
          line-height: 26px;
          color: white;
          font-size: 22px;
          font-weight: 700;
        }
      }
      &.hs-menu-depth-2 {
        > a {
          padding-top: 11px;
          padding-bottom: 11px;
          line-height: 19px;
          color: #c5d5ef;
          font-size: 16px;
          font-weight: 400;
        }
      }
      > ul > li ul {
        display: none;
      }
    }
  }
  a {
    display: block;
    padding-right: 16px;
    padding-left: 16px;
    text-decoration: none;
  }
  &:not(.open) {
    display: none;
    visibility: hidden;
  }
  &__close-btn {
    position: relative;
  }
}
