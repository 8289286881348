.billboard {
  position: relative;
  border-bottom: 6px solid $blue-medium;
  background-color: white;
  overflow: hidden;
  text-align: center;
  z-index: 1;
  [class*='constrain'] {
    padding-top: em(80);
    padding-bottom: em(80);
    z-index: 2;
    @media (max-width:479px) {
      position: relative;
    }
    @media (min-width:480px) {
      position: absolute;
      top:50%;
      left:0;
      right:0;
      margin: auto !important;
      transform:translateY(-50%);
    }
  }
  &__underlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    @media (max-width: 767px) {
      background-position: left top;
    }
    @media (min-width: 768px) {
      background-position: right top;
    }
  }
  &__gradient {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(to left,  rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    height: 100%;
    width: 100%;
  }
  &__content {
    position: relative;
    z-index: 2;
    max-width: 540px;
    text-align: left;
    h1 {
      // @extend %header-line;
    }
    .cta-btn {
      @extend %cta-btn--gold;
      @extend %cta-btn--large;
      @extend %cta-btn;
    }
  }
  &--small {
    @media (min-width: 480px) {
      min-height: 220px;
    }
  }
  &--medium {
    @media (min-width: 480px) {
      min-height: 326px;
    }
  }
  &--large {
    @media (min-width: 480px) {
      min-height: 546px;
    }
  }
  &--full {
    height: 100vh;
  }
}

_:-ms-input-placeholder, :root .billboard [class*='constrain'] {
  // IE fix for constrain on billboards
  @media (min-width:1200px) {
    transform:translateY(-50%) translateX(45px);
  }
}
