.case-study-header {
  text-align: center;
  &__inner {
    margin: auto;
    padding: em(40) em(20);
    max-width: em(1006);
  }
  &__subtitle,
  &__title {
    text-align: center;
  }
  &__subtitle {
    margin-top: 0;
    margin-bottom: em(12, 18);
    text-transform: uppercase;
  }
  &__title {
    margin: 0;
  }
  &__banner {
    margin: 0;
    border-top: 6px solid $blue-medium;
    border-bottom: 6px solid $blue-medium;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: em(323);
  }
}
