.post {
  display: flex;
  margin-bottom: em(20);
  padding: em(30);
  text-decoration: none;
  color: inherit;
  box-sizing: border-box;
  flex-direction: column;
  &:hover {
    .post__title {
      color: $blue-light;
    }
  }
  &__title {
    @extend %h4;
    display: block;
    transition: .25s color ease-in-out;
    margin: 0;
    border-bottom: 1px solid #dce1e8;
    padding-top: em(18,22);
    padding-bottom: em(25,22);
    color: $blue-dark;
    font-weight: 700;
  }
  &__date,
  &__author {
    line-height: (18 / 16);
    color: #0042ba;
    font-size: em(16);
    font-weight: 700;
  }
  &__author {
    margin-right: 7.5px;
    max-width: 75%;
  }
  &__date {
    margin-left: 7.5px;
  }
  &__content {
    display: block;
    padding-top: em(19, 17);
    padding-bottom: em(24, 17);
    line-height: (30 / 17);
    color: #333;
    font-size: em(17);
    font-weight: 400;
    flex-grow: 1;
  }
  &__read-more {
    display: block;
    margin: 1em 0;
  }
  &__bottom {
    display: flex;
    border-top: 1px solid #bbc5d7;
    padding-top: em(25);
    width: auto !important;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }
  &__image {
    display: block;
    img {
      width: 100%;
      height: auto;
    }
  }
  &:not(.card) {
    .post__image {
      max-height: none;
    }
  }
  &.card {
    border: 1px solid #bbc5d7;
    border-radius: 4px;
    box-shadow: 0 em(2) em(4) rgba(0, 0, 0, .2);
    @media (min-width: 768px) {
      .post__image {
        max-height: em(163);
        overflow: hidden;
      }
    }
  }
}

// IE visual padding fix
_:-ms-lang(x), .post__date {
  padding-right: em(5);
}
