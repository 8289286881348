.list-layout {
  text-align: left;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  @media (min-width: 768px) {
    &__one-col {
      ul > li {
        width: 100%;
        flex-basis: 100%;
      }
    }
    &__two-col,
    &__four-col {
      ul > li {
        width: calc(50% - 10px);
        flex-basis: calc(50% - 10px);
      }
    }
    &__three-col {
      ul > li {
        width: calc(33% - 10px);
        flex-basis: calc(33% - 10px);
      }
    }
  }
  @media (min-width: 992px) {
    &__four-col {
      ul > li {
        width: calc(25% - 10px);
        flex-basis: calc(25% - 10px);
      }
    }
  }
  &__item {}
}
