.site-footer {
  z-index: 1;
  margin-top: em(-50);
  background-color: white;
  padding: 0 20px;
  &__inner {
    @extend %constrain;
    position: relative;
  }
  &__top {
    .site-footer__inner {
      display: flex;
      padding: em(126) 0 em(60);
      padding-bottom: em(30);
    }
  }
  &__seal {
    margin: 0 em(50) 0 0;
    width: em(66);
    height: em(65);
    @media (max-width: 1199px) {
      display: none;
    }
  }
  &__main-menu {
    @media (min-width: 768px) {
      flex-grow: 1;
    }
    > div > ul {
      display: flex;
      text-align: left;
      @media (max-width: 767px) {
        flex-wrap: wrap;
        box-sizing: border-box;
        display: -webkit-flex;
        -webkit-box-orient: horizontal; 
        -webkit-box-direction: normal; 
        -webkit-flex-direction: row; 
        -webkit-flex-wrap: wrap;
      }
      @media (min-width: 767px) {
        justify-content: space-between;
      }
      > li {
        display: flex;
        flex-direction: column;
        @media (max-width: 767px) {
          width: 50%;
        }
        @media (max-width: 767px) {
          margin-bottom: em(30);
        }
        > a {
          padding-bottom: em(6, 16);
          line-height: (30 / 16);
          color: $blue-medium;
          font-size: em(16);
          font-weight: 700;
        }
        > ul {
          display: flex;
          flex-direction: column;
          li {
            display: block;
          }
          a {
            padding-top: em(10, 14);
            padding-bottom: em(10, 14);
            line-height: (20 / 14);
            color: $gray-dark;
            font-size: em(14);
            font-weight: 400;
          }
        }
      }
    }
  }
  &__secondary-menu {
    > .inner {
      @extend %constrain;
      border-bottom: 1px solid #dce1e8;
      padding-bottom: em(25);
    }
    ul {
      > li {
        padding-left: em(12);
        padding-right: em(12);
        @media(max-width:767px) {
          width: 100%;
        }
        a {
          font-size: .9em;
          @media(max-width:767px) {
            font-size: 1.1em;
            padding-top: em(7);
            padding-bottom: em(7);
          }
        }
      }
    }
  }
  &__bottom {
    .site-footer__inner {
      padding: em(26) 0 em(62);
      text-align: center;
    }
    &__logos {
      @media(min-width: 768px) {
        display: flex;
        justify-content: space-between;
      }
      padding-top: em(10);
      padding-bottom: em(25);
      &--col {
        &:first-child {
          @media(min-width: 768px) {
            display: flex;
          }
        }
        > div {
          @media(min-width: 768px) {
            padding-left: em(15);
            padding-right: em(15);
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
          }
          @media(max-width: 767px) {
            padding-top: em(10);
            padding-bottom: em(10);
          }
        }
      }
      .bbb {
        img {
          max-width: em(120);
        }
      }
      .nhsca {
        img {
          max-width: em(48);
        }
      }
      .old-republic {
        img {
          width: em(490);
          max-width: em(350);
          @media(max-width: 767px) {
            max-width: 100%;
          }
        }
      }
    }
    &__top {
      display: flex;
      margin: auto;
      width: 84%;
      max-width: 850px;
      @media (max-width: 991px) {
        flex-direction: column;
      }
      @media (min-width: 992px) {
        justify-content: space-between;
      }
    }
    &__botttom {
    }
  }
  &__details {
    flex-grow: 1;
    ul {
      display: flex;
      justify-content: space-between;
      @media (max-width: 767px) {
        flex-wrap: wrap;
        display: -webkit-flex;
        -webkit-box-orient: horizontal; 
        -webkit-box-direction: normal; 
        -webkit-flex-direction: row; 
        -webkit-flex-wrap: wrap;
        li {
          padding-bottom: em(9, 16);
          display: block !important;
          width: 100%;
        }
      }
      li {
        margin: 0 em(10, 16);
        line-height: (30 / 16);
        color: $blue-dark;
        font-size: em(16);
        font-weight: 700;
      }
    }
  }
  &__social {
    @media (max-width: 991px) {
      margin-top: em(15);
      margin-bottom: em(15);
    }
    @media (min-width: 992px) {
      margin-left: em(30);
    }
  }
  &__copyright {
    padding-top: em(3);
    p {
      line-height: (15 / 14);
      color: $gray-dark;
      font-family: $serif;
      font-size: em(14);
      font-style: italic;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
    }
  }
  a {
    display: block;
    transition: .25s color ease-in-out;
    text-decoration: none;
    &:hover {
      color: $blue-light;
    }
  }
}

body {
  &.lp {
    .site-footer {
      margin-top: em(-15);
      &__top {
        display: none;
      }
      &__bottom {
        padding-top: 0;
      }
      &__inner {
        @extend %constrain--wide;
        border-top: 1px solid #dce1e8;
      }
      &__secondary-menu {
        padding-top: em(50);
      }
    }
  }
}

