.accordion-section {
  border: 1px solid $gray-light;
  margin: 0 auto -1px;
  overflow: hidden;
  &--open {
    .accordion-section {
      &__header {
        &:after {
          transform: translateY(-50%) rotate(-180deg);
        }
      }
      &__content {
        height: auto;
        opacity: 1;
        padding: em(5) em(20) em(10);
      }
    }
  }
  &__header {
    padding: em(10) em(75) em(10) em(20);
    position: relative;
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: em(26);
      transform: translateY(-50%);
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      transition: 0.3s all ease-in-out;
      border-width: em(10) em(10) 0 em(10);
      border-color: #003087 transparent transparent transparent;
    }
  }
  &__title {
    @extend %h4;
    margin-bottom: 0;
  }
  &__content {
    height: 0;
    opacity: 0;
    transition: 0.25s opacity ease-in-out;
    padding: 0;
  }
}

.accordion-group {
  @extend %constrain;
  margin: 0 auto em(30) !important;
}