%flex-region-helper {
  display: block;
  opacity: .25;
  background-color: #ddd;
  padding: em(30) 0;
  text-align: center;
}

%flex-region-full {
  content: 'Full Width Flexible Region';
}

%flex-region-sheet {
  content: 'Sheet Width Flexible Region';
}

%flex-region-nested {
  content: 'Nested Flexible Region';
}

.hs-inline-edit {
  .flex-top-sheet,
  .flex-bottom-sheet,
  .flex-middle-sheet,
  .flex-top-full,
  .flex-bottom-full {
    span.hs_cos_wrapper_type_widget_container:before {
      @extend %flex-region-helper;
    }
    .hs_cos_wrapper_widget:last-child:after {
      @extend %flex-region-helper;
    }
  }
  .flex-top-sheet,
  .flex-bottom-sheet {
    span.hs_cos_wrapper_type_widget_container {
      margin: em(20) 0;
    }
    span.hs_cos_wrapper_type_widget_container:before {
      @extend %flex-region-sheet;
    }
    .hs_cos_wrapper_widget:last-child:after {
      @extend %flex-region-sheet;
    }
  }
  .flex-top-full,
  .flex-bottom-full {
    span.hs_cos_wrapper_type_widget_container:before {
      @extend %flex-region-full;
    }
    .hs_cos_wrapper_widget:last-child:after {
      @extend %flex-region-full;
    }
  }
  .flex-nested {
    span.hs_cos_wrapper_type_widget_container:before {
      @extend %flex-region-nested;
    }
    .hs_cos_wrapper_widget:last-child:after {
      @extend %flex-region-nested;
    }
  }
  .nested-layout--template {
    display: block !important;
    &:not(.nested-layout__hide),
    & > span {
      display: block !important;
    }
    &:before,
    &:after {
      @extend %flex-region-helper;
      margin: em(20) 0;
      border: 2px dashed $gray;
      background-color: transparent;
      width: 100%;
      content: 'Nested Layout Section - Adjust the layout or disable this section in the module list.';
    }
    span.hs_cos_wrapper_type_widget_container {
      width: 100%;
      > div {
        display: block;
        width: 50%;
      }
    }
  }
}
