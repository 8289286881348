.testimonial {
  background-color: $blue-dark;
  padding: 8.5em 1.25em 8.5em;
  text-align: center;
  &__inner {
    margin: auto;
    width: 72%;
    text-align: center;
  }
  &__quote {
    margin: 0 auto em(56, 32);
    border: 0;
    padding: 0;
    width: 100%;
    text-align: center;
    line-height: (36 / 32);
    color: white;
    font-family: $serif;
    font-size: em(32);
    font-weight: 400;
    font-style: italic;
  }
  &__citation {
    display: block;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    line-height: (22 / 18);
    color: $blue-shade;
    font-size: em(18);
    font-weight: 700;
  }
}
