h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  font-family: $sans-serif;
}

h1,
%h1 {
  margin-bottom: em(10, 35);
  line-height: (43 / 35);
  color: $blue-medium;
  font-size: em(35);
}

h2,
%h2 {
  margin-bottom: em(10, 30);
  line-height: (37 / 30);
  color: $blue-dark;
  font-size: em(30);
}

h3,
%h3 {
  margin-bottom: em(10, 26);
  line-height: (32 / 26);
  color: $gold;
  font-size: em(26);
}

h4,
%h4 {
  margin-bottom: em(10, 22);
  line-height: (27 / 22);
  color: $blue-dark;
  font-size: em(22);
}

h5,
%h5 {
  margin-bottom: em(8, 18);
  line-height: (22 / 18);
  color: $blue-shade;
  font-size: em(18);
}

h6,
%h6 {
  margin-bottom: em(8, 16);
  line-height: (22 / 16);
  color: $blue-medium;
  font-size: em(16);
}

p,
%p {
  margin-bottom: em(15, 17);
  line-height: (30 / 17);
  font-size: em(17);
  color: #333;
  &.extra-margin {
    margin-bottom: em(48, 17);
  }
  &.small {
    font-size: em(15);
  }
}

%header-line {
  position: relative;
  padding-top: 24px;
  &:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $blue-medium;
    width: 72px;
    height: 6px;
    content: '';
  }
}

%header-underline {
  padding-bottom: 15px;
  border-bottom: 1px solid #dce1e8;
  margin-bottom: 18px;
}

ul,
ol {
  line-height: 1.7;
  &.inline {
    list-style: none;
    li {
      display: inline-block;
      margin-right: em(10, 17);
      line-height: (30 / 17);
      font-size: em(17);
    }
  }
}

a {
  transition: .25s color ease-in-out;
  text-decoration: underline;
  color: #0042ba;
  &:hover {
    text-transform: none;
    color: $blue-light;
  }
  &.blue-dark {
    color: $blue-dark !important;
    text-decoration: none !important;
  }
}